<ng-container *transloco="let t; scope: 'shared'">
  <ng-container *ngIf="price().data as oPrice">
    <button
      *ngIf="oPrice.oPriceScaleInfos.length > 0"
      type="button"
      class="js-discount-btn hover:text-primary"
      (click)="open($event)"
      (mouseenter)="open($event)"
      (mouseleave)="close()"
    >
      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
        />
      </svg>
    </button>
    <div [class.hidden]="!isOpen" class="js-discount absolute bottom-6 bg-white left-0 -translate-x-1/2 shadow rounded-md z-30 w-80">
      <table class="text-sm">
        <thead>
          <tr class="border-1 border-b-2">
            <th>{{ t("shared.article-scaled-discount.table.amount") }}</th>
            <th>{{ t("shared.article-scaled-discount.table.price") }}</th>
          </tr>
        </thead>
        <tr *ngFor="let item of oPrice.oPriceScaleInfos">
          <td class="py-1.5 px-4 pt-4">{{ item.decQuantityFrom }} {{ item.sQuantityUnit }}</td>
          <td class="py-1.5 px-4 pt-4">{{ item.decPriceNet | translocoCurrency }}</td>
        </tr>
      </table>
    </div>
  </ng-container>
</ng-container>
