import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { Article, Price } from '@lobos/common-v3';
import { TranslocoLocaleModule } from '@jsverse/transloco-locale';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { ArticleService } from '../../services/catalog/article.service';
import { derivedAsync } from 'ngxtension/derived-async';
import { createPendingObserverResult } from '@ngneat/query';

@Component({
  selector: 'app-article-scaled-discount',
  templateUrl: './article-scaled-discount.component.html',
  styleUrls: ['./article-scaled-discount.component.scss'],
  standalone: true,
  imports: [TranslocoModule, NgIf, NgFor, AsyncPipe, TranslocoLocaleModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticleScaledDiscountComponent {
  article = input.required<Article>();
  articleService = inject(ArticleService);

  price = derivedAsync(
    () =>
      this.articleService.getPrice({
        articleId: this.article().sArticleID as string,
        currency: 'CHF',
        factor1: 1,
        factor2: 1,
        factor3: 1,
        qty: this.article().lngSalesPriceUnit,
        unit: this.article().sQuantityUnitSales,
      }).result$,
    {
      initialValue: createPendingObserverResult<Price>(),
    },
  );

  public isOpen: boolean = false;

  open(event: Event) {
    event.stopPropagation();
    this.isOpen = true;
  }

  close() {
    this.isOpen = false;
  }
}
